import { useCallback, useEffect, useMemo, useState } from "react";
import * as anchor from "@project-serum/anchor";

import styled from "styled-components";
import { Container, Snackbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { TokenClaimer } from "./TokenClaim";
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import TwitterIcon from '@mui/icons-material/Twitter';
import Stack from '@mui/material/Stack';
import { red } from '@mui/material/colors';

import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from "@solana/web3.js";

import {createTheme,GlobalStyles,ThemeProvider} from "@mui/material";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState
} from "./candy-machine";
import { AlertState, formatNumber, getAtaForMint, toDate } from "./utils";
import { MintCountdown } from "./MintCountdown";
import { MintButton } from "./MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { sendTransaction } from "./connection";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { usePoller } from "./hooks/usePoller";
//@ts-ignore
import confetti from "canvas-confetti";
import { Token } from "@solana/spl-token";
import { fontStyle } from "@mui/system";
import { FontDownload } from "@mui/icons-material";
require("./Bg.css");

function throwConfetti(): void {
  confetti({
    particleCount: 200,
    spread: 70,
    origin: { y: 0.6 },
  });
}

const ConnectButton = styled(WalletDialogButton)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: #ff9b0f;
  color: #fff5e8;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  background-color: #fff5e8;
  border-radius: 22px;
  margin: 5px;
  box-shadow: 7px 7px 7px 0px rgb(0, 0, 0, 0.5);
  opacity: 94%;
  height: 500px;
`;

const StyledPaperBottom = styled(Paper)`
  padding: 20px;
  background-color: #fff5e8;
  border-radius: 22px;
  margin: 5px;
  margin-top: 20px;
  box-shadow: 7px 7px 7px 0px rgb(0, 0, 0, 0.5);
  opacity: 94%;
`;

const MobilePaper = styled(Paper)`
  padding: 20px;
  background-color: #fff5e8;
  border-radius: 22px;
  margin: 20px;
  box-shadow: 10px 10px 10px 0px rgb(0, 0, 0, 0.5);
  opacity: 94%;
  // height: 575px;
`;

const StyledPaperActive = styled(Paper)`
  padding: 8px;
  background-color: #f8dbbd;
  border-radius: 22px;
  margin: 10px;

  justifyContent: "center";
  alignItems: "center";
  textAlign: "center";
  verticalAlign: "middle";
`;

const StyledPaperActiveDesc = styled(Paper)`
  padding: 8px;
  background-color: #f8dbbd;
  border-radius: 22px;
  margin: 10px;
  height: 500px;
  justifyContent: "center";
  alignItems: "center";
  textAlign: "center";
  verticalAlign: "middle";
`;

const StyledPaperNotActive = styled(Paper)`
  padding: 6px;
  background-color: #f8dbbd;
  border-radius: 22px;
  margin: 12px;
  marginTop: 15px;
  opacity: 30%;
`;
const MintContainer = styled.div``; // add your owns styles here


export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
}

const Home = (props: HomeProps) => {
  const [animatedBg, setAnimatedBg] = useState(false);
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });


// ---------- CONFIGURATION FOR PROJECTS INFO HERE --------------------  

const quickdropId1 = "592iRq3VFQxgQ6jxqzU9tSL1hbv7KgFHwQZCDNPYaJ3L";
const quickdropId2 = "6652USg6EVSAo2hBc1Tu8U63k6LiwjFhwWDbTCn8TdgP";
const quickdropId3 = "";

  const totalSupply = "7777";

  const phaseOnePrice = "1.8"
  const phaseOneName = "Whitelist 1"
  const phaseOneTime = "October 15th, 1:00pm EST"
  const phaseOneToken = "6Jwxi3oLjFdSdeJEacKHzU4pHfUCy4o4PnctHegn62Dp"

  const phaseTwoPrice = "1.8";
  const phaseTwoName = "Whitelist 2"
  const phaseTwoTime = "October 15th, 2:00pm EST"
  const phaseTwoToken = "BP21cbHqVrCfGSat889dBzz1CYyaYTzZjLeqfnY93nNi"

  // const phaseThreePrice = "1.8";
  // const phaseThreeName = "WL Specimens"
  // const phaseThreeTime = "October G6th, TIME"
  // const phaseThreeToken = "

  const phasePublicPrice = "1.8";
  const phasePublicName = "Public Sale"
  const phasePublicTime = "October 15th, 3:00pm EST"
  //dont change public token, use this one forever 4Vo9Z6krkukMhDzcXRMukKb9YooNNDgqtaKjEyH2capd
  const phasePublicToken = "4Vo9Z6krkukMhDzcXRMukKb9YooNNDgqtaKjEyH2capd"

  const firstDescription = "Sorcies is a collection of 7777 unique NFTs looking to Build a Better Tomorrow. Sorcies will build themselves as a community-focused PFP project through a combination of branding, fashion, storytelling, and game theory."
  const secondDescription = ""


  const HERO = "/hero.gif";
  const LOGO = "/logo.png";
  const [isActive, setIsActive] = useState(false);
  const [currentShift, setCurrentShift] = useState<number>(0);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();
  const [width, height] = useWindowSize()
  const onlyWidth = useWindowWidth()

  const [isMobile, setIsMobile] = useState <boolean>(false)
    useEffect(()=>{setIsMobile(onlyWidth<999)},[onlyWidth])
  const [isNotMobile, setIsNotMobile] = useState <boolean>(false)
    useEffect(()=>{setIsNotMobile(onlyWidth>1000)},[onlyWidth])
  
  const test = 1
  const [isTest, setIsTest] = useState <boolean>(false)
    useEffect(()=>{setIsTest(test===1)},[test])

  const [isSoldOut, setIsSoldOut] = useState <boolean>(false)
    useEffect(()=>{setIsSoldOut(itemsRemaining===0)},[itemsRemaining])

  const [isPhase1, setIsPhase1] = useState <boolean>(false)
     useEffect(()=>{setIsPhase1(candyMachine?.state.whitelistMintSettings?.mint.toBase58()===phaseOneToken)},[candyMachine?.state.whitelistMintSettings?.mint])
  const [isPhase2, setIsPhase2] = useState <boolean>(false)
    useEffect(()=>{setIsPhase2(candyMachine?.state.whitelistMintSettings?.mint.toBase58()===phaseTwoToken)},[candyMachine?.state.whitelistMintSettings?.mint])
  const [isPublic, setIsPublic] = useState <boolean>(false)
    useEffect(()=>{setIsPublic(candyMachine?.state.whitelistMintSettings?.mint.toBase58()===phasePublicToken)},[candyMachine?.state.whitelistMintSettings?.mint])
  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) {
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            connection
          );
         
          const currentSlot = await connection.getSlot();
          const blockTime = (await connection.getBlockTime(
            currentSlot
          )) as number;
          const shift = new Date().getTime() / 1000 - blockTime;

          setCurrentShift(shift);

          let active =
            cndy?.state.goLiveDate?.toNumber() <
            new Date().getTime() / 1000 + shift;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              setDiscountPrice(undefined);
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint
            );
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                "There was a problem fetching whitelist token balance"
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log("There was a problem fetching SPL token balance");
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(anchorWallet.publicKey)
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            let limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                severity: "error",
                hideDuration: null,
              });
            } else if (
              e.message.startsWith("failed to get info about account")
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                severity: "error",
                hideDuration: null,
              });
            }
          } else {
            setAlertState({
              open: true,
              message: `${e}`,
              severity: "error",
              hideDuration: null,
            });
          }
          console.log(e);
        }
      } else {
        setAlertState({
          open: true,
          message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
          severity: "error",
          hideDuration: null,
        });
      }
    },
    [anchorWallet, props.candyMachineId, props.rpcHost]
  );

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = []
  ) => {
    try {
      setIsUserMinting(true);
      document.getElementById("#identity")?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setAlertState({
            open: true,
            message: "Please sign account setup transaction",
            severity: "info",
          });
          setupMint = await createAccountsForMint(
            candyMachine,
            wallet.publicKey
          );
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            setAlertState({
              open: true,
              message:
                "Setup transaction succeeded! Please sign minting transaction",
              severity: "info",
            });
          } else {
            setAlertState({
              open: true,
              message: "Mint failed! Please try again!",
              severity: "error",
            });
            setIsUserMinting(false);
            return;
          }
        } else {
          setAlertState({
            open: true,
            message: "Please sign minting transaction",
            severity: "info",
          });
        }

        let mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          beforeTransactions,
          afterTransactions,
          setupMint ?? setupTxn
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              "processed"
            );
          console.log("Metadata status: ", !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          // manual update since the refresh might not detect
          // the change immediately
          let remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setSetupTxn(undefined);
          setAlertState({
            open: true,
            message: "Mint succeeded!",
            severity: "success",
            hideDuration: 7000,
          });
          refreshCandyMachineState("processed");
        } else if (status && !status.err) {
          setAlertState({
            open: true,
            message:
              "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
            severity: "error",
            hideDuration: 8000,
          });
          refreshCandyMachineState();
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
          refreshCandyMachineState();
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction timeout! Please try again.";
        } else if (error.message.indexOf("0x137")) {
          console.log(error);
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
      // updates the candy machine state to reflect the latest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const toggleMintButton = () => {
    let active = !isActive || isPresale;

    if (active) {
      if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
        active = false;
      }
      if (endDate && Date.now() >= endDate.getTime()) {
        active = false;
      }
    }

    if (
      isPresale &&
      candyMachine!.state.goLiveDate &&
      candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
    ) {
      setIsPresale((candyMachine!.state.isPresale = false));
    }

    setIsActive((candyMachine!.state.isActive = active));
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);

  return (
    <>
      {animatedBg && (
        <div className="section section--featured">
          <div className="row-container">
            <div className="line">
              <div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
              </div>
            </div>

            <div className="line second">
              <div>
                <div className="row">
                  <img src="line-1-min.png" alt="" />
                </div>
                <div className="row">
                  <img src="line-1-min.png" alt="" />
                </div>
              </div>
            </div>
            <div className="line third">
              <div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
                <div className="row">
                  <img src="line-3-min.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="shadow">
            <span></span>
          </div>
        </div>
      )}

{isNotMobile&&<div style={{display: 'flex',
          justifyContent: 'center'}}>

<Box style={{  display: "flex", marginLeft: "50px", marginRight: "50px", marginTop: "20px", maxWidth: "1350px" }} alignItems="center">

  <Grid container spacing={3}>
    <Grid item xs={7}>
      <StyledPaper>
          <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div style={{borderRadius: '20px', overflow: 'hidden', marginRight: '15px', marginTop: '15px'}}>
                  <img
                  src={LOGO}
                  alt=""
                  width="100%"
                  style={{ borderRadius: "5px" }}
                  />
                  </div>
                </Grid>

                {/* <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <IconButton color="inherit" aria-label="upload picture" component="label">
                      <input hidden accept="image/*" type="file" />
                      <PhotoCamera />
                    </IconButton>
                    <IconButton color="inherit" aria-label="twitter" component="label" size="large">
                      <TwitterIcon />
                    </IconButton>
                  </Stack>
                </Grid>   */}
                
                <Grid item xs={12}>
                  <div style={{overflow: 'hidden', marginRight: '15px', marginTop: '15px'}}>
                    <Typography variant="body1">
                      {firstDescription}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <div style={{borderRadius: '20px', overflow: 'hidden', marginRight: '5px', marginTop: '15px'}}>
                <img
                src={HERO}
                alt=""
                width="100%"
                style={{ borderRadius: "5px" }}
                />
                </div>
              </Grid>
            </Grid>  

            <Grid container>
              <Grid item xs={12}>
                <div style={{borderRadius: '20px', overflow: 'hidden', marginRight: '15px', marginTop: '0px'}}>
                <h3 style={{
                    fontSize: "14pt",
                    color: "#16162d"
                    }}>
                    {secondDescription}
                  </h3>
                </div>
              </Grid>
            </Grid>

      </StyledPaper>
    </Grid>

    {/* CANDY MACHINE */}
    
    <Grid item xs={5}>
    <StyledPaper>

{/* PHASE 1 */}
    <Grid item xs={12}>
      {isPhase1 ? 
      <StyledPaperActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phaseOneName} - {phaseOnePrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              {phaseOneTime}
            </Typography>
          </div>
          <div style={{
          display: 'flex',
          justifyContent: 'center',
            }}>
            <TokenClaimer quickdropId={quickdropId1}/>
            </div>
        </Box>
      </StyledPaperActive> :
      <StyledPaperNotActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phaseOneName} - {phaseOnePrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              {phaseOneTime}
            </Typography>
          </div>
        </Box>
      </StyledPaperNotActive>}
    </Grid>  

{/* PHASE 2 */}
    <Grid item xs={12}>
      {isPhase2 ? 
      <StyledPaperActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phaseTwoName} - {phaseTwoPrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              {phaseTwoTime}
            </Typography>
          </div>
          <div style={{
          display: 'flex',
          justifyContent: 'center',
            }}>
          <TokenClaimer quickdropId={quickdropId2}/>
          </div>
        </Box>
      </StyledPaperActive> :
      <StyledPaperNotActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phaseTwoName} - {phaseTwoPrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              {phaseTwoTime}
            </Typography>
          </div>
        </Box>
      </StyledPaperNotActive>}
    </Grid> 

{/* PUBLIC PHASE AND COUNTDOWN*/}
    <Grid item xs={12}>

      {isPublic ? 
      <StyledPaperActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phasePublicName} - {phasePublicPrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              {phasePublicTime}
            </Typography>
          </div>
        </Box>
      </StyledPaperActive> :
      <StyledPaperNotActive>
        <Box m="auto">
        <Grid container spacing={2}>
        <Grid item xs={12}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phasePublicName} - {phasePublicPrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              {phasePublicTime}
            </Typography>
          </div>
        </Grid>  

        

    </Grid>
        </Box>
      </StyledPaperNotActive>}

    </Grid>
    <Box sx={{
      marginTop: '35px'
    }}>
    <Grid container spacing={2}>
    
      <Grid item xs={6}>
        <Box>
          <div style={{
            display: 'flex',
            justifyContent: 'left',
          }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "#16162d",
                fontSize: "17px",
              }}>
                {`${Number(totalSupply) - Number(itemsRemaining)} / ${totalSupply} Minted`}
            </Typography>
          </div>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box>
          <div style={{
            display: 'flex',
            justifyContent: 'right',
          }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "#16162d",
                fontSize: "14px",
              }}>
                {`${(((Number(totalSupply)-Number(itemsRemaining))/Number(totalSupply))*100).toPrecision(3)}%`}
            </Typography>
          </div>
        </Box>
      </Grid>
    </Grid>  
    </Box> 

    <Box sx={{ width: '100%',marginTop: '2px' }}>
      <LinearProgress color="warning" variant="determinate" value={((Number(totalSupply)-Number(itemsRemaining))/(Number(totalSupply)))*100} />
    </Box>

    <Box style={{
      marginTop: "20px",
    }}>
    <Grid item xs={12}>
      {!wallet.connected ? (
              <ConnectButton>Connect Wallet</ConnectButton>
            ) : (
              <>
                {candyMachine && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    wrap="nowrap"
                  >
                  </Grid>
                )}
                <Box m="auto">
                <div style={{
          display: 'flex',
          justifyContent: 'center',
            }}>
                  <MintContainer>
                    {candyMachine?.state.isActive &&
                    candyMachine?.state.gatekeeper &&
                    wallet.publicKey &&
                    wallet.signTransaction ? (
                      //@ts-ignore
                      <GatewayProvider
                        wallet={{
                          publicKey:
                            wallet.publicKey ||
                            new PublicKey(CANDY_MACHINE_PROGRAM),
                          //@ts-ignore
                          signTransaction: wallet.signTransaction,
                        }}
                        gatekeeperNetwork={
                          candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                        }
                        clusterUrl={
                          props.network === WalletAdapterNetwork.Devnet
                            ? "https://api.devnet.solana.com"
                            : rpcUrl
                        }
                        handleTransaction={async (transaction: Transaction) => {
                          setIsUserMinting(true);
                          const userMustSign = transaction.signatures.find(
                            (sig) => sig.publicKey.equals(wallet.publicKey!)
                          );
                          if (userMustSign) {
                            setAlertState({
                              open: true,
                              message: "Please sign one-time Civic Pass issuance",
                              severity: "info",
                            });
                            try {
                              transaction = await wallet.signTransaction!(
                                transaction
                              );
                            } catch (e) {
                              setAlertState({
                                open: true,
                                message: "User cancelled signing",
                                severity: "error",
                              });
                              // setTimeout(() => window.location.reload(), 2000);
                              setIsUserMinting(false);
                              throw e;
                            }
                          } else {
                            setAlertState({
                              open: true,
                              message: "Refreshing Civic Pass",
                              severity: "info",
                            });
                          }
                          try {
                            await sendTransaction(
                              props.connection,
                              wallet,
                              transaction,
                              [],
                              true,
                              "confirmed"
                            );
                            setAlertState({
                              open: true,
                              message: "Please sign minting",
                              severity: "info",
                            });
                          } catch (e) {
                            setAlertState({
                              open: true,
                              message:
                                "Solana dropped the transaction, please try again",
                              severity: "warning",
                            });
                            console.error(e);
                            // setTimeout(() => window.location.reload(), 2000);
                            setIsUserMinting(false);
                            throw e;
                          }
                          await onMint();
                        }}
                        broadcastTransaction={false}
                        options={{ autoShowModal: false }}
                      >
                        <MintButton
                          candyMachine={candyMachine}
                          isMinting={isUserMinting}
                          setIsMinting={(val) => setIsUserMinting(val)}
                          onMint={onMint}
                          isActive={
                            isActive ||
                            (isPresale && isWhitelistUser && isValidBalance)
                          }
                        />
                      </GatewayProvider>
                    ) : (
                      <MintButton
                        candyMachine={candyMachine}
                        isMinting={isUserMinting}
                        setIsMinting={(val) => setIsUserMinting(val)}
                        onMint={onMint}
                        isActive={
                          isActive ||
                          (isPresale && isWhitelistUser && isValidBalance)
                        }
                      />
                    )}
                  </MintContainer>
                  </div>
                </Box> 
              </>
            )}
    </Grid>
    </Box>
  </StyledPaper>
    </Grid>

    <Grid item xs={12}>
      <StyledPaperBottom>
        <Box>
        <div style={{
            marginTop: "20px"
          }}>
          <Grid container>
            {/* <Grid item xs={12}>
            <div style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "0px"                
                  }}>
              <Grid container>
                      <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "100%",
                      fontFamily: "inherit",
                      fontSize: "35px",
                      justifyContent: "center",
                      color: "black"
                    }}>
                      Roadmap & Team
                    </Typography>
                    </Grid>
                    </Grid>
                    </div>
</Grid> */}
            

            <Grid item xs={3}>
              <StyledPaperActiveDesc>
              <div style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "2px"                
                  }}>
              <Grid container>
                      <Grid item xs={12}>
                      <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px",
                      marginBottom: "7px"
                    }}>
                      Branding
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "95%",
                      fontFamily: "inherit",
                      fontSize: "17px",
                      justifyContent: "center",
                      color: "black",
                    }}>
                      Sorcies' minimalistic yet distinctive style of art should be appreciated by everyone. It's simple, fun, and easy to understand. The art used for NFTs, graphics, logos, clothing, etc. will always stay consistent and unique because consistency and uniqueness will instill a sentiment that will lead to global recognition.
                    </Typography>
                    </Grid>
                    </Grid>
                    </div>
              </StyledPaperActiveDesc>
            </Grid>

            <Grid item xs={3}>
              <StyledPaperActiveDesc>
              <div style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "2px"                
                  }}>
              <Grid container>
                      <Grid item xs={12}>
                      <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px",
                      marginBottom: "7px"
                    }}>
                      Fashion
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "95%",
                      fontFamily: "inherit",
                      fontSize: "17px",
                      justifyContent: "center",
                      color: "black",
                    }}>
                      Sorcies will deploy a top-of-the-line fashion line built from the ground up that will act as a pillar of support for the Sorcies brand. Sorcies will build their entire clothing line around 5 words: Limited, Top-Dollar, Unique, Designer. Expect consistent themed drops designed by Sorcies' in-house designers, as well as collaborative parties.                    </Typography>
                    </Grid>
                    </Grid>
                    </div>
              </StyledPaperActiveDesc>
            </Grid>

            <Grid item xs={3}>
            <StyledPaperActiveDesc>
              <div style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "2px"                
                  }}>
              <Grid container>
                      <Grid item xs={12}>
                      <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px",
                      marginBottom: "7px"
                    }}>
                      Storytelling
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "95%",
                      fontFamily: "inherit",
                      fontSize: "17px",
                      justifyContent: "center",
                      color: "black",
                    }}>
                      The Sorcies' lore will be carefully crafted and developed to create a fun, suspenseful, and mysterious story which will allow for the creation of addicting story campaigns. Sorcies' holders will have the chance to immerse themselves within the Sorcies' world through unique gamified story campaigns. Campaign participants can expect $SOR, NFTs, NFT upgrades/changes, and of course, hours of entertainment.
                      </Typography>
                    </Grid>
                    </Grid>
                    </div>
              </StyledPaperActiveDesc>
            </Grid>

            <Grid item xs={3}>
            <StyledPaperActiveDesc>
              <div style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginTop: "2px"                
                  }}>
              <Grid container>
                      <Grid item xs={12}>
                      <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px",
                      marginBottom: "7px"
                    }}>
                      Game Theory
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "95%",
                      fontFamily: "inherit",
                      fontSize: "17px",
                      justifyContent: "center",
                      color: "black",
                    }}>
                    Sorcies' native token, Soranium ($SOR) will be built around strategic game theory models to create a token with more uses than one can imagine. Disclaimer: The people will decide the token's value based on their valuation of its utility -- there will be no liquidity pool.                      
                    </Typography>
                    </Grid>
                    </Grid>
                    </div>
              </StyledPaperActiveDesc>
            </Grid>
            
          </Grid>
        </div>  

          <div style={{
            marginTop: "10px"
          }}>
          <Grid container>
            <Grid item xs={3}>
              <StyledPaperActive>
                  <div style={{
                    margin: "7px"
                  }}>
                    <img
                    src={"/voided.png"}
                    alt=""
                    width="100%"
                    style={{ borderRadius: "15px" }}
                    />
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>
                      <Grid container>
                      <Grid item xs={12}>
                    <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px"
                      }}>
                      voided
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "100%",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      justifyContent: "center",
                      color: "black"
                    }}>
                      Co-Founder / Project Director
                    </Typography>
                    </Grid>
                    </Grid>
                    </div>
              </StyledPaperActive>
            </Grid>

            <Grid item xs={3}>
              <StyledPaperActive>
                  <div style={{
                    margin: "7px"
                  }}>
                    <img
                    src={"/solsanta.png"}
                    alt=""
                    width="100%"
                    style={{ borderRadius: "15px" }}
                    />
                    </div>
                    <div style={{
                      display: "flex",
                      justifyContent: "center"
                    }}>
                      <Grid container>
                      <Grid item xs={12}>
                    <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px"
                    }}>
                      Sol Santa
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Typography style={{
                      display: "flex",
                      width: "100%",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      justifyContent: "center",
                      color: "black"
                    }}>
                      Co-Founder / Brand Director
                    </Typography>
                    </Grid>
                    </Grid>
                    </div>
              </StyledPaperActive>
            </Grid>

            <Grid item xs={3}>
              <StyledPaperActive>
                    <div style={{
                      margin: "7px"
                    }}>
                      <img
                      src={"/aterus.png"}
                      alt=""
                      width="100%"
                      style={{ borderRadius: "15px" }}
                      />
                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <Grid container>
                        <Grid item xs={12}>
                      <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px"
                       }}>
                        Aterus
                      </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography style={{
                        display: "flex",
                        width: "100%",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        justifyContent: "center",
                        color: "black"
                      }}>
                        Marketing Lead
                      </Typography>
                      </Grid>
                      </Grid>
                      </div>
              </StyledPaperActive>
            </Grid>

            <Grid item xs={3}>
              <StyledPaperActive>
                    <div style={{
                      margin: "7px"
                    }}>
                      <img
                      src={"/dreems.png"}
                      alt=""
                      width="100%"
                      style={{ borderRadius: "15px" }}
                      />
                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <Grid container>
                        <Grid item xs={12}>
                    <Typography variant="h3" style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: "7px"
                    }}>
                        Dreems
                      </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <Typography style={{
                        display: "flex",
                        width: "100%",
                        fontFamily: "inherit",
                        fontSize: "16px",
                        justifyContent: "center",
                        color: "black"
                      }}>
                        Community Lead
                      </Typography>
                      </Grid>
                      </Grid>
                      </div>
              </StyledPaperActive>
            </Grid>
        
          </Grid>
          </div>
        </Box>
      </StyledPaperBottom>
    </Grid>

  </Grid>

</Box>
</div>}



  {isMobile&&<Box style={{ minHeight: "100vh", display: "flex" }} alignItems="center">
        <Container maxWidth="xs" style={{ position: "relative" }}>
          <MobilePaper>
            {" "}
            <img
              src={LOGO}
              alt=""
              width="100%"
              style={{ borderRadius: "5px" }}
            />
          </MobilePaper>
          <MobilePaper>
            <div>
              <img
                src={HERO}
                alt=""
                width="100%"
                style={{ borderRadius: "20px", margin:"auto" }}
              />
            </div>
          </MobilePaper>

          <MobilePaper>
          <Grid item xs={12}>

{/* PHASE 1 */}
    <Grid item xs={12}>
      {isPhase1 ? 
      <StyledPaperActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phaseOneName} - {phaseOnePrice}◎
              </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              Ends {phaseTwoTime}
            </Typography>
          </div>
          <div style={{
          display: 'flex',
          justifyContent: 'center',
            }}>
            <TokenClaimer quickdropId={quickdropId1}/>
            </div>
        </Box>
      </StyledPaperActive> : <div>
      </div>
}
    </Grid>  

{/* PHASE 2 */}
    <Grid item xs={12}>
      {isPhase2 ? 
      <StyledPaperActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phaseTwoName} - {phaseTwoPrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              Ends {phasePublicTime}
            </Typography>
          </div>
          <div style={{
          display: 'flex',
          justifyContent: 'center',
            }}>
          <TokenClaimer quickdropId={quickdropId2}/>
          </div>
        </Box>
      </StyledPaperActive> :
<div></div>}
    </Grid> 

{/* PHASE 3 */}
 

{/* PUBLIC SALE */}
    <Grid item xs={12}>
      {isPublic ? 
      <StyledPaperActive>
        <Box m="auto">
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px',
            }}>
            <Typography variant="h4">
              {phasePublicName} - {phasePublicPrice}◎
            </Typography>
         </div>  
         <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
          marginBottom: '5px',
            }}> 
            <Typography variant="h4">
              Currently Minting
            </Typography>
          </div>
        </Box>
      </StyledPaperActive> :
<div></div>}
    </Grid> 

{/* PROGRESS BAR */}
    <Box sx={{
      marginTop: '20px'
    }}>
    <Grid container spacing={2}>
    
      <Grid item xs={6}>
        <Box>
          <div style={{
            display: 'flex',
            justifyContent: 'left',
          }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "#16162d",
                fontSize: "17px",
              }}>
                {`${Number(totalSupply)-Number(itemsRemaining)} / ${totalSupply} Minted`}
            </Typography>
          </div>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box>
          <div style={{
            display: 'flex',
            justifyContent: 'right',
          }}>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "#16162d",
                fontSize: "14px",
              }}>
                {`${(((Number(totalSupply)-Number(itemsRemaining))/Number(totalSupply))*100).toPrecision(3)}%`}
            </Typography>
          </div>
        </Box>
      </Grid>
    </Grid>  
    </Box> 
    <Box sx={{ width: '100%',marginTop: '2px' }}>
      <LinearProgress color="warning" variant="determinate" value={((Number(totalSupply)-Number(itemsRemaining))/(Number(totalSupply)))*100} />
    </Box>
    


    <Box style={{
      marginTop: "15px"
    }}>
    <Grid item xs={12}>
      {!wallet.connected ? (
              <ConnectButton>Connect Wallet</ConnectButton>
            ) : (
              <>
                {candyMachine && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    wrap="nowrap"
                  >

                    {/* <Grid item xs={12}>
                      {isActive && endDate && Date.now() < endDate.getTime() ? (
                        <>
                          <MintCountdown
                            key="endSettings"
                            date={getCountdownDate(candyMachine, currentShift)}
                            style={{ justifyContent: "flex-end" }}
                            status="COMPLETED"
                            onComplete={toggleMintButton}
                          />
                          <Typography
                            variant="caption"
                            align="center"
                            display="block"
                            style={{ fontWeight: "bold" }}
                          >
                            TO END OF MINT
                          </Typography>
                        </>
                      ) : (
                        <>
                          <MintCountdown
                            key="goLive"
                            date={getCountdownDate(candyMachine, currentShift)}
                            style={{ justifyContent: "flex-end" }}
                            status={
                              candyMachine?.state?.isSoldOut ||
                              (endDate && Date.now() > endDate.getTime())
                                ? "COMPLETED"
                                : isPresale
                                ? "PRESALE"
                                : "LIVE"
                            }
                            onComplete={toggleMintButton}
                          />
                          {isPresale &&
                            candyMachine.state.goLiveDate &&
                            candyMachine.state.goLiveDate.toNumber() >
                              new Date().getTime() / 1000 + currentShift && (
                              <Typography
                                variant="caption"
                                align="center"
                                display="block"
                                style={{ fontWeight: "bold" }}
                              >
                                UNTIL PUBLIC MINT
                              </Typography>
                            )}
                        </>
                      )}
                    </Grid> */}
                  </Grid>
                )}

<Box m="auto">
                <div style={{
          display: 'flex',
          justifyContent: 'center',
            }}>
                <MintContainer>
                  {candyMachine?.state.isActive &&
                  candyMachine?.state.gatekeeper &&
                  wallet.publicKey &&
                  wallet.signTransaction ? (
                    //@ts-ignore
                    <GatewayProvider
                      wallet={{
                        publicKey:
                          wallet.publicKey ||
                          new PublicKey(CANDY_MACHINE_PROGRAM),
                        //@ts-ignore
                        signTransaction: wallet.signTransaction,
                      }}
                      gatekeeperNetwork={
                        candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                      }
                      clusterUrl={
                        props.network === WalletAdapterNetwork.Devnet
                          ? "https://api.devnet.solana.com"
                          : rpcUrl
                      }
                      handleTransaction={async (transaction: Transaction) => {
                        setIsUserMinting(true);
                        const userMustSign = transaction.signatures.find(
                          (sig) => sig.publicKey.equals(wallet.publicKey!)
                        );
                        if (userMustSign) {
                          setAlertState({
                            open: true,
                            message: "Please sign one-time Civic Pass issuance",
                            severity: "info",
                          });
                          try {
                            transaction = await wallet.signTransaction!(
                              transaction
                            );
                          } catch (e) {
                            setAlertState({
                              open: true,
                              message: "User cancelled signing",
                              severity: "error",
                            });
                            // setTimeout(() => window.location.reload(), 2000);
                            setIsUserMinting(false);
                            throw e;
                          }
                        } else {
                          setAlertState({
                            open: true,
                            message: "Refreshing Civic Pass",
                            severity: "info",
                          });
                        }
                        try {
                          await sendTransaction(
                            props.connection,
                            wallet,
                            transaction,
                            [],
                            true,
                            "confirmed"
                          );
                          setAlertState({
                            open: true,
                            message: "Please sign minting",
                            severity: "info",
                          });
                        } catch (e) {
                          setAlertState({
                            open: true,
                            message:
                              "Solana dropped the transaction, please try again",
                            severity: "warning",
                          });
                          console.error(e);
                          // setTimeout(() => window.location.reload(), 2000);
                          setIsUserMinting(false);
                          throw e;
                        }
                        await onMint();
                      }}
                      broadcastTransaction={false}
                      options={{ autoShowModal: false }}
                    >
                      <MintButton
                        candyMachine={candyMachine}
                        isMinting={isUserMinting}
                        setIsMinting={(val) => setIsUserMinting(val)}
                        onMint={onMint}
                        isActive={
                          isActive ||
                          (isPresale && isWhitelistUser && isValidBalance)
                        }
                      />
                    </GatewayProvider>
                  ) : (
                    <MintButton
                      candyMachine={candyMachine}
                      isMinting={isUserMinting}
                      setIsMinting={(val) => setIsUserMinting(val)}
                      onMint={onMint}
                      isActive={
                        isActive ||
                        (isPresale && isWhitelistUser && isValidBalance)
                      }
                    />
                  )}
                </MintContainer>
                </div>
                </Box>
              </>
            )}
            </Grid>
            </Box>
  

</Grid>
          </MobilePaper>
        </Container>

        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </Box>}

    </>
  );
};








const getCountdownDate = (
  candyMachine: CandyMachineAccount,
  currentShift: number
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? new anchor.BN(candyMachine.state.goLiveDate.toNumber() + currentShift)
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined
  );
};

export default Home;
